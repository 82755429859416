import { useState } from "react";
import AwaitingRefound from "./AwaitingRefound/AwaitingRefound";
import s from "./Sessions.module.css";

type TabValueType = "awaitRefound";

const Sessions = () => {
  const [activeTab, setActiveTab] = useState<TabValueType>("awaitRefound");
  const allTabs: {
    value: TabValueType;
    label: string;
    component: JSX.Element;
  }[] = [
    {
      label: "Awaiting refound",
      value: "awaitRefound",
      component: <AwaitingRefound />,
    },
  ];
  return (
    <div className={s.container}>
      <div className={s.tabsBlock}>
        {allTabs.map((item, index) => (
          <div
            className={activeTab === item.value ? s.activeTab : s.notActiveTab}
            onClick={() => {
              setActiveTab(item.value);
            }}
            key={index}
          >
            <span>{item.label}</span>
            <span
              className={
                activeTab === item.value
                  ? s.tabActiveIndicator
                  : s.indicatorHidden
              }
            ></span>
          </div>
        ))}
      </div>
      <div className={s.contentBlock}>
        {allTabs.find((el) => el.value === activeTab)?.component}
      </div>
    </div>
  );
};

export default Sessions;
